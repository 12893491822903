import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../index';
import { v4 as uuidv4 } from 'uuid';

import { LanguageOptions } from '../../resources';

export interface AppState {
  activePage: string;
  language: LanguageOptions;
  zipcode: string;
  fingerprintId?: string;
  sessionId: string;
  loading: boolean;
}

const initialState: AppState = {
  activePage: 'languages',
  language: 'english',
  zipcode: '',
  sessionId: uuidv4(),
  loading: false,
};

const appSlice = createSlice({
  name: 'app',
  initialState: initialState,
  reducers: {
    setActivePage: (state: AppState, action: PayloadAction<string>) => {
      state.activePage = action.payload;
    },
    setLanguage: (state: AppState, action: PayloadAction<LanguageOptions>) => {
      state.language = action.payload;
    },
    setZipcode: (state: AppState, action: PayloadAction<string>) => {
      state.zipcode = action.payload;
    },
    setFingerprintId: (state: AppState, action: PayloadAction<string>) => {
      state.fingerprintId = action.payload;
    },
    setLoading: (state: AppState, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
  },
});

//Export actions
export const { setActivePage, setLanguage, setZipcode, setFingerprintId, setLoading } = appSlice.actions;

//Export individual pieces of state
export const activePageState = (state: RootState) => state.appReducer.activePage;
export const activeLanguageState = (state: RootState) => state.appReducer.language;
export const zipcodeState = (state: RootState) => state.appReducer.zipcode;
export const fingerprintIdState = (state: RootState) => state.appReducer.fingerprintId;
export const sessionIdState = (state: RootState) => state.appReducer.sessionId;
export const loadingState = (state: RootState) => state.appReducer.loading;

export default appSlice.reducer;
