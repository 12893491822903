import english from './english';
import spanish from './spanish';
import chinese from './chinese';
import hmong from './hmong';
import vietnamese from './vietnamese';

export type LanguageOptions = 'english' | 'spanish' | 'chinese' | 'hmong' | 'vietnamese';

interface Category {
  TITLE: string;
  DESCRIPTION: string;
}

export type TextOptions = {
  TITLE: string;
  LANGUAGE_TEXT: {
    TITLE: string;
    LANGUAGE_LIST: {
      english: string;
      spanish: string;
      chinese: string;
      hmong: string;
      vietnamese: string;
    };
  };
  WELCOME_TEXT: {
    TEXT: JSX.Element;
    START: string;
  };
  STEPPER_TEXT: {
    BUDGET: string;
    OUTCOME: string;
    SUCCESS: string;
  };
  ZIPCODE_TEXT: string;
  BUDGET_TEXT: {
    DESCRIPTION_TEXT: JSX.Element;
    PROGRESS_TITLE: string;
    CATEGORIES: {
      access: Category & {
        PLACES_TEXT: string;
        PLACES: {
          COMMUNITY_CENTERS: string;
          JOBS: string;
          LIBRARIES: string;
          PARKS: string;
          SCHOOLS: string;
          SHOPS: string;
          OTHER: string;
        };
      };
      climate: Category;
      equity: Category;
      maintenance: Category;
      safety: Category;
      technology: Category;
      other: Category & { INPUT_PLACEHOLDER: string };
    };
    BUDGET_WARNING: string;
    BUTTON_TEXT: string;
  };
  OUTCOME_TEXT: {
    MORE_PRIORITY_TITLE: string;
    LESS_PRIORITY_TITLE: string;
    MORE_RECOMMENDED_TITLE: string;
    CATEGORIES: {
      access: string;
      climate: string;
      equity: string;
      maintenance: string;
      safety: string;
      technology: string;
      other: string;
    };
    BOTTOM_PAGE_TEXT: JSX.Element;
    BUDGET_BUTTON: string;
    SUBMIT_BUTTON: string;
  };
  SUCCESS_TEXT: {
    TITLE: string;
    SUBTITLE: string;
    SUBSCRIPTION_TEXT: string;
    SUBSCRIPTION_INPUT_TEXT: string;
    SUBSCRIPTION_INPUT_ERROR: string;
    SUBSCRIPTION_SUCCESS_TEXT: string;
    OPTIONAL_TITLE: string;
    OPTIONAL_SUBTITLE: string;
    SUBSCRIBE_BUTTON: string;
    SUBMIT_BUTTON: string;
    SUBMISSION_SUCCESS_TEXT: string;
    FORM: {
      GENDER: {
        LABEL: string;
        VALUES: {
          FEMALE: string;
          MALE: string;
          GENDER_DIVERSE: string;
          OTHER: string;
          NON_DISCLOSURE: string;
          [key: string]: string;
        };
        PLEASE_SPECIFY_LABEL: string;
      };
      ETHNICITY: {
        LABEL: string;
        VALUES: {
          ASIAN: string;
          BLACK: string;
          HISPANIC: string;
          NATIVE_AMERICAN: string;
          PACIFIC_ISLANDER: string;
          WHITE: string;
          OTHER: string;
          NON_DISCLOSURE: string;
          [key: string]: string;
        };
        PLEASE_SPECIFY_LABEL: string;
      };
      AGE: {
        LABEL: string;
        VALUES: {
          UNDER_18: string;
          BETWEEN_18_AND_25: string;
          BETWEEN_25_AND_35: string;
          BETWEEN_35_AND_45: string;
          BETWEEN_45_AND_55: string;
          BETWEEN_55_AND_65: string;
          OLDER_65: string;
          NON_DISCLOSURE: string;
          [key: string]: string;
        };
      };
      LANGUAGE: {
        LABEL: string;
        VALUES: {
          CHINESE: string;
          ENGLISH: string;
          HMONG: string;
          SPANISH: string;
          VIETNAMESE: string;
          OTHER: string;
          NON_DISCLOSURE: string;
          [key: string]: string;
        };
        PLEASE_SPECIFY_LABEL: string;
      };
      LGBTQ: {
        LABEL: string;
        VALUES: {
          NO: string;
          YES: string;
          NON_DISCLOSURE: string;
          [key: string]: string;
        };
      };
      DISABILITY: {
        LABEL: string;
        VALUES: {
          NO: string;
          YES: string;
          NON_DISCLOSURE: string;
          [key: string]: string;
        };
      };
      INCOME: {
        LABEL: string;
        VALUES: {
          UNDER_15: string;
          BETWEEN_15_AND_30: string;
          BETWEEN_30_AND_50: string;
          BETWEEN_50_AND_75: string;
          BETWEEN_75_AND_100: string;
          BETWEEN_100_AND_150: string;
          MORE_THAN_150: string;
          NON_DISCLOSURE: string;
          [key: string]: string;
        };
      };
      HOUSEHOLD: {
        LABEL: string;
        VALUES: {
          ONE: string;
          TWO: string;
          THREE: string;
          FOUR: string;
          FIVE: string;
          SIX_OR_MORE: string;
          NON_DISCLOSURE: string;
          [key: string]: string;
        };
      };
      VEHICLES: {
        LABEL: string;
        VALUES: {
          ZERO: string;
          ONE: string;
          TWO: string;
          THREE: string;
          FOUR_OR_MORE: string;
          NON_DISCLOSURE: string;
          [key: string]: string;
        };
      };
      BICYCLES: {
        LABEL: string;
        VALUES: {
          ZERO: string;
          ONE: string;
          TWO: string;
          THREE: string;
          FOUR_OR_MORE: string;
          NON_DISCLOSURE: string;
          [key: string]: string;
        };
      };
      NEIGHBORHOOD: {
        LABEL: string;
        NEIGHBORHOOD_NAMES: string[];
        VALUES: {
          OTHER: string;
          NON_DISCLOSURE: string;
          [key: string]: string;
        };
      };
    };
  };
};

interface Text {
  english: TextOptions;
  spanish: TextOptions;
  chinese: TextOptions;
  hmong: TextOptions;
  vietnamese: TextOptions;
}

const TEXT: Text = {
  english: english,
  spanish: spanish,
  chinese: chinese,
  hmong: hmong,
  vietnamese: vietnamese,
};

export default TEXT;
