import { combineReducers, configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import logger from 'redux-logger';

import appReducer from './slices/appSlice';
import budgetReducer from './slices/budgetSlice';
import formReducer from './slices/formSlice';

const rootReducer = combineReducers({
  appReducer,
  budgetReducer,
  formReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default configureStore({
  reducer: rootReducer,
  middleware: [logger, ...getDefaultMiddleware()],
});
