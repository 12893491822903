import { unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core/styles';

export default createMuiTheme({
  palette: {
    primary: {
      main: '#773dbd',
    },
    secondary: {
      main: '#e8b000',
    },
    background: {
      default: '#eee',
    },
  },
});
