import { useSelector } from 'react-redux';
import { activeLanguageState } from '../../store/slices/appSlice';
import { Typography } from '@material-ui/core';
import TEXT from '../../resources';
import LogoSVG from '../../assets/logo';

import './logo.scss';

const Logo = () => {
  const activeLanguage = useSelector(activeLanguageState);
  const title = TEXT[activeLanguage].TITLE;

  return (
    <div className='header-container'>
      <div className='logo-container'>
        <LogoSVG />
      </div>
      <Typography component='h1' className='title'>
        {title}
      </Typography>
    </div>
  );
};

export default Logo;
