import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../index';
// import shuffle from 'lodash/shuffle';

export type Category = 'access' | 'climate' | 'equity' | 'maintenance' | 'safety' | 'technology' | 'other';
export interface BudgetState {
  values: {
    access: number;
    climate: number;
    equity: number;
    maintenance: number;
    safety: number;
    technology: number;
    other: number;
    [category: string]: number;
  };
  selectedAccessPlaces: string[];
  otherCategoryDescription: string;
  slidesOrder: Category[];
}

const initialState: BudgetState = {
  values: {
    access: 0,
    climate: 0,
    equity: 0,
    maintenance: 0,
    safety: 0,
    technology: 0,
    other: 0,
  },
  selectedAccessPlaces: [],
  otherCategoryDescription: '',
  slidesOrder: ['climate', 'access', 'equity', 'maintenance', 'safety', 'technology'], // shuffle(['access', 'climate', 'equity', 'maintenance', 'safety', 'technology']),
};

const budgetSlice = createSlice({
  name: 'budget',
  initialState,
  reducers: {
    setCategoryBudget: (state: BudgetState, action: PayloadAction<{ category: string; value: number }>) => {
      const { category, value } = action.payload;
      state.values[category] = value;
    },
    setOtherCategoryDescription: (state: BudgetState, action: PayloadAction<string>) => {
      state.otherCategoryDescription = action.payload;
    },
    addAccessPlace: (state: BudgetState, action: PayloadAction<string>) => {
      state.selectedAccessPlaces.push(action.payload);
    },
    removeAccessPlace: (state: BudgetState, action: PayloadAction<string>) => {
      const index = state.selectedAccessPlaces.indexOf(action.payload);
      if (index !== -1) {
        state.selectedAccessPlaces.splice(index, 1);
      }
    },
    emptySelectedAccessPlaces: (state: BudgetState) => {
      state.selectedAccessPlaces.length = 0;
    },
  },
});

//Export actions
export const {
  setCategoryBudget,
  setOtherCategoryDescription,
  addAccessPlace,
  removeAccessPlace,
  emptySelectedAccessPlaces,
} = budgetSlice.actions;

//Export individual pieces of state
export const budgetValuesState = (state: RootState) => state.budgetReducer.values;
export const otherCategoryDescriptionState = (state: RootState) => state.budgetReducer.otherCategoryDescription;
export const slidersOrderState = (state: RootState) => state.budgetReducer.slidesOrder;
export const selectedAccessPlacesState = (state: RootState) => state.budgetReducer.selectedAccessPlaces;

export const totalValueState = (state: RootState) =>
  Object.values(state.budgetReducer.values).reduce((a, b) => a + b, 0);

export default budgetSlice.reducer;
