import neighborhoods from './neightborhoods';

const content = {
  TITLE: 'Những Kế Hoạch Ưu Tiên Về Giao Thông Vận Tải Ở Sacramento',
  LANGUAGE_TEXT: {
    TITLE: 'Please Select Your Language',
    LANGUAGE_LIST: {
      english: 'English',
      spanish: 'Español',
      chinese: '汉语',
      hmong: 'Lus Hmoob',
      vietnamese: 'tiếng Việt',
    },
  },
  WELCOME_TEXT: {
    TEXT: (
      <>
        <p>Xung quanh đây điều gì quan trọng nhất đối với bạn? Ví dụ, Nếu Thành phố Sacramento, được ưu tiên:</p>

        <ul>
          <li>
            Đầu tư vào lĩnh vực giao thông vận tải ở những nơi có mức thu nhập thấp và đa cộng đồng dân tộc da màu?
          </li>
          <li>Xây dựng các vỉa hè ở những nơi vẫn chưa có?</li>
          <li>Lấp các ổ gà?</li>
          <li>Giảm tai nạn giao thông?</li>
        </ul>

        <p>
          Kế Hoạch Về Những Ưu Tiên Giao Thông Vận Tải sẽ xác định những cải thiện trong giao thông nào mà Thành phố cần
          ưu tiên, và chúng tôi kêu gọi sự giúp đỡ của bạn.
        </p>

        <p>Sacramento trải dài hơn 100 dặm vuông và sẽ được thực hiện trên hơn:</p>

        <ul>
          <li>1.800 dặm dành cho đường phố</li>
          <li>250 dặm dành cho làn đường xe đạp</li>
          <li>78 dặm phân chia sử dụng cho việc mở đường mòn đi bộ</li>
          <li>43.000 cái đèn giao thông</li>
        </ul>

        <p>
          Nó sẽ tốn khoản 5 tỷ đô la để sửa chữa và nâng cấp tất cả các con đường phố của Thành Phố, vỉa hè, các làn
          đường dành cho xe đạp, các cây cầu, và các biển báo hiệu giao thông. Tuy nhiên, với chỉ một khoảng trung bình
          42 tỷ đô la mỹ trên năm dành cho ngành giao thông vận tải, nó sẽ mất đến hơn 100 năm để xây dựng được hoàn tất
          những kế hoạch hiện tại. Trước tiên thành phố nên làm gì?
        </p>

        <p>
          Để biết thêm thông tin chi tiết, mời ghé thăm website{' '}
          <a target='_blank' rel='noreferrer' href='http://www.sactransportation.org/'>
            http://sactransportation.org/
          </a>
          .
        </p>
      </>
    ),
    START: 'BẮT ĐẦU',
  },
  STEPPER_TEXT: {
    BUDGET: 'NGÂN SÁCH',
    OUTCOME: 'KẾT QUẢ',
    SUCCESS: 'GỬI',
  },
  ZIPCODE_TEXT: 'Xin vui lòng nhập địa chỉ Mã bưu chính của nhà bạn',
  BUDGET_TEXT: {
    DESCRIPTION_TEXT: (
      <>
        <p>
          Sử dụng thanh trượt ở phía dưới để nhằm chia sẻ điểu gì quang trọng nhất tới bạn cho ngành giao thông vận tải
          ở Sacrameto. Ngân sách thành phố chỉ có ở mức hạn hẹp, vì vậy nên chúng ta không thể ưu tiên cho tất cả mọi
          thứ. Đó là những sự lựa chọn khó khăn đối với chúng tôi. Bạn có thể chọn trong số 6 lựa chọn được mô tả bên
          dưới hoặc viết ý kiến riêng của bạn.
        </p>
        <p>
          <b>
            Bài nộp của bạn ở đây sẽ cho biết Thành phố được ưu tiên các dự án như thế nào và những gì sẽ nhận được tài
            trợ trong tương lai gần.
          </b>
          Ví dụ, nếu bạn ưu tiên đến việc bảo trì, Thành phố có thể có ít tiền hơn cho việc cải tiến công nghệ. Nếu bạn
          ưu tiên chất lượng không khí và sức khỏe, Thành phố sẽ sớm được xây dựng những dự án đó sớm hơn.
        </p>
        <p>
          <i>
            Xin lưu ý rằng khu vực chuyên trở - không phải ở Thành phố Sacramento - vận hành xe buýt và đường sắt nhẹ
            trong Thành phố. Khu vực chuyên chở có ngân sách riêng, do đó là lý do tại sao dịch vụ vận chuyển không được
            liệt kê như một lựa chọn ở đây. Chia sẻ những suy nghĩ của bạn về khu vực chuyên chở, xin vui lòng ghé thăm:{' '}
            <a href='http://www.sacrt.com/customerfeedback/' rel='noreferrer' target='_blank'>
              http://www.sacrt.com/customerfeedback/
            </a>
          </i>
        </p>
      </>
    ),
    PROGRESS_TITLE: 'Tổng Quát Về Các Ưu Tiên',
    CATEGORIES: {
      access: {
        TITLE: 'Liên kết các địa điểm với nhau',
        DESCRIPTION:
          'Ưu tiên đến sự kết nối giữa các địa điểm có nghĩa là ưu tiên cho việc thực hiện dự án để giúp đưa mọi người đi đến các trường học một cách thuận lợi hơn, các công viên, các thư viện, các nhà văn hóa, các địa điểm mua sắm, nơi làm việc, và những địa điểm khác và bao gồm những vỉa hè, những nơi để băng qua đường, những con đường dành cho xe đạp, cải thiện tu sửa các cây cầu và đường phố.',
        PLACES_TEXT: 'Những nơi nên cần ưu tiên? Chọn ba nơi đứng top đầu của bạn:',
        PLACES: {
          COMMUNITY_CENTERS: 'Các nhà văn hóa',
          JOBS: 'Các nơi làm việc',
          LIBRARIES: 'Các thư viện',
          PARKS: 'Các công viên',
          SCHOOLS: 'Các trường học',
          SHOPS: 'Các khu mua sắm',
          OTHER: 'Các địa điểm khác',
        },
      },
      climate: {
        TITLE: 'Chất lượng không khí và Sức Khỏe',
        DESCRIPTION:
          'Ưu tiên đến chất lượng không khí và sức khỏe có nghĩa là ưu tiên cho các dự án khuyến khích đi bộ, đi xe đạp, và đi các phương tiện xe điện như là các vỉa hè,  băng qua đường, làn đường xe đạp, tiếp cận phương tiện công cộng, hoặc các trạm sạc xe điệm.',
      },
      equity: {
        TITLE: 'Sự công bằng',
        DESCRIPTION:
          'Ưu tiên công bằng có nghĩa là cải thiện điều kiện giao thông vận tải trong các làng cộng đồng mà chưa từng được đầu tư trong lịch sử, thiếu cơ sở hạ tầng cơ bản, hoặc là những cộng đồng da màu có thu nhập thấp. Điều này có thể bao gồm các vỉa hè, những lối đi bộ sang đường, đường dành cho xe đạp, những khu vực đang bảo trì, vỉa hè mới hoặc cải thiện kết nối với các xe buýt và các xe lửa.',
      },
      maintenance: {
        TITLE: 'Bảo trì',
        DESCRIPTION:
          'Ưu tiên việc bảo trì có nghĩa là đang sửa chữa các ổ gà, phục hồi các đường phố và đường đi bộ / đi xe đạp, và thêm đường dốc ở lề đường tại chỗ dành cho người đi bộ qua đường.',
      },
      safety: {
        TITLE: 'An toàn',
        DESCRIPTION:
          'Ưu tiên an toàn có nghĩa là ưu tiên những cải tiến, nâng cấp ở những nơi nghiêm trọng nhất và xảy ra nhiều vụ va chạm chết người và có thể bao gồm các dấu hiệu, các đường kẻ vỉa hè, các băng qua đường, các làn đường dành cho xe đạp, các báo hiệu giao thông, các báo hiệu cho người đi bộ, hoặc tốc độ và giảm làn đường.',
      },
      technology: {
        TITLE: 'Công nghệ',
        DESCRIPTION:
          'Ưu tiên công nghệ có nghĩa là nâng cấp các tín hiệu giao thông và thi hành tín hiệu và thêm các tín hiệu dành cho người đi bộ và thiết bị an toàn khác.',
      },
      other: {
        TITLE: 'Những thứ khác',
        DESCRIPTION: 'Những điều chúng ta đã bỏ lỡ?',
        INPUT_PLACEHOLDER: 'Nhập ý kiến của bạn tại đây.',
      },
    },
    BUDGET_WARNING: 'Hãy chắc chắn rằng các ưu tiên của bạn tổng là 100% trước khi xác nhận các giá trị của bạn.',
    BUTTON_TEXT: 'XEM KẾT QUẢ',
  },
  OUTCOME_TEXT: {
    MORE_PRIORITY_TITLE: 'KẾT QUẢ: Dựa trên giá trị của bạn, bạn gới thiệu Thành phố ưu tiên…',
    LESS_PRIORITY_TITLE: 'và chi tiêu ít hơn vào:',
    MORE_RECOMMENDED_TITLE:
      'Bạn đã chọn không ưu tiên bất kỳ giá trị nào. Bạn đang đề xuất sự ưu tiên của Thành phố vào…',
    CATEGORIES: {
      access:
        'Các kết nối tới các địa điểm: Các dự án đã tạo sự thuận lợi cho việc đi đến trường, các công viên, các thư viện, các nhà văn hóa, các khu mua sắm, các nơi làm việc và các địa điểm khác.',
      climate:
        'Chất lượng không khí và sức khỏe: Những dự án đó khuyến khích sự đi bộ, đi xe đạp, và các phương tiện xe điện.',
      equity:
        'Sự Công Bằng:  Những dự án trong các cộng đồng chưa từng thấy đã có sự đầu tư như vậy bao giờ trong lịch sử, thiếu những cơ sở hạ tầng cơ bản, hoặc là các cộng đồng da màu có thu nhập thấp.',
      maintenance:
        'Bảo trì: những dự án tu sửa các  ổ gà, sửa lại các đường phố và các đường đi bộ, và thêm các lề đường dốc tại các lối băng qua đường.',
      safety:
        'An toàn: Những dự án tu sửa lại những  nơi thường xảy ra các vụ va chạm và gây chết người nghiêm trọng nhất.',
      technology:
        'Công nghệ: dự án nâng cấp tín hiệu giao thông và vận hành tín hiệu và thêm tín hiệu dành cho người đi bộ và các thiết bị an toàn khác.',
      other: 'Những vấn đề khác',
    },
    BOTTOM_PAGE_TEXT: (
      <div>KẾT QUẢ: Dựa trên thanh trượt cho từng giá trị. Nhấp vào nút GIÁ TRỊ bên dưới để thay đổi kết quả.</div>
    ),
    BUDGET_BUTTON: 'GIÁ TRỊ',
    SUBMIT_BUTTON: 'XÁC NHẬN',
  },
  SUCCESS_TEXT: {
    TITLE: 'Cảm ơn!',
    SUBTITLE: 'Các ưu tiên của bạn đã được xác nhận thành công. Chúng tôi rất cảm kích sự tham gia của bạn.',
    SUBSCRIPTION_TEXT: 'Hãy tiếp tục tham gia! Nhấp vào nút bên dưới để đăng ký danh sách email của chúng tôi.',
    SUBSCRIPTION_INPUT_TEXT: 'Nhập địa chỉ email của bạn tại đây',
    SUBSCRIPTION_INPUT_ERROR: 'Email không hợp lệ',
    SUBSCRIPTION_SUCCESS_TEXT: 'Email đã đăng ký',
    OPTIONAL_TITLE: 'Câu hỏi tùy chọn',
    OPTIONAL_SUBTITLE:
      'Vui lòng trả lời những câu hỏi sau đây để giúp đảm bảo chúng tôi đã tiếp cận được tới một mẫu người đại diện của các dân cư Thành phố Câu trả lời của bạn sẽ được ẩn danh.',
    SUBSCRIBE_BUTTON: 'Đăng ký',
    SUBMIT_BUTTON: 'XÁC NHẬN',
    SUBMISSION_SUCCESS_TEXT: 'Cảm ơn bạn! Phản hồi của bạn đã được gửi.',
    FORM: {
      GENDER: {
        LABEL: 'Tôi xác định là:',
        VALUES: {
          FEMALE: 'Nữ (bao gồm cả nữ chuyển giới)',
          MALE: 'Nam ( bao gồm cả nam chuyển giới',
          GENDER_DIVERSE: 'Đa dạng giới tính / Vừ nam vừa nữ',
          OTHER: 'Giớ tính khác',
          NON_DISCLOSURE: 'Không trả lời',
        },
        PLEASE_SPECIFY_LABEL: 'hãy nêu rõ',
      },
      ETHNICITY: {
        LABEL: 'Tôi xác định dân tộc của mình là:',
        VALUES: {
          ASIAN: 'Người Châu á',
          BLACK: 'Người Mỹ da đem /  Gốc phi',
          HISPANIC: 'Người Tây Ban Nha / Latinx',
          NATIVE_AMERICAN: 'Người Mỹ bản xứ',
          PACIFIC_ISLANDER: 'Người Thái Bình Dương',
          WHITE: 'Người Da Trắng',
          OTHER: 'Giớ tính khác',
          NON_DISCLOSURE: 'Không trả lời',
        },
        PLEASE_SPECIFY_LABEL: 'hãy nêu rõ',
      },
      AGE: {
        LABEL: 'Bạn bao nhiêu tuổi?',
        VALUES: {
          UNDER_18: 'Dưới 18',
          BETWEEN_18_AND_25: '18-24',
          BETWEEN_25_AND_35: '25-34',
          BETWEEN_35_AND_45: '35-44',
          BETWEEN_45_AND_55: '45-54',
          BETWEEN_55_AND_65: '55-64',
          OLDER_65: '65+',
          NON_DISCLOSURE: 'Không trả lời',
        },
      },
      LANGUAGE: {
        LABEL: 'Bạn đang nói thứ tiếng gì ở nhà?',
        VALUES: {
          CHINESE: 'Tiếng Trung Quốc',
          ENGLISH: 'Tiếng Anh',
          HMONG: 'Tiếng H’mông',
          SPANISH: 'Tiếng Tấy Ban Nha',
          VIETNAMESE: 'Tiếng Việt',
          OTHER: 'Giớ tính khác',
          NON_DISCLOSURE: 'Không trả lời',
        },
        PLEASE_SPECIFY_LABEL: 'hãy nêu rõ',
      },
      LGBTQ: {
        LABEL:
          'Bạn có coi mình là một thành viên của cộng đồng người Đồng tính nữ, Đồng tính nam, Song tính, Chuyển giới và/ hoặc Luyến ái (ĐĐSCL)',
        VALUES: {
          NO: 'Không',
          YES: 'Có',
          NON_DISCLOSURE: 'Không trả lời',
        },
      },
      DISABILITY: {
        LABEL: 'Có ai trong gia đình bạn bị khuyết tật ảnh hưởng đến khả năng vận động của họ không, bao gồm cả bạn?',
        VALUES: {
          NO: 'Không',
          YES: 'Có',
          NON_DISCLOSURE: 'Không trả lời',
        },
      },
      INCOME: {
        LABEL: 'Mức thu nhập hàng năm trong gia đình của bạn là bao nhiêu?',
        VALUES: {
          UNDER_15: 'Dưới $15,000',
          BETWEEN_15_AND_30: 'Trong khoảng từ $15,000 đến $29,999',
          BETWEEN_30_AND_50: 'Trong khoảng từ $30,000 đến $49,999',
          BETWEEN_50_AND_75: 'Trong khoảng từ $50,000 đến $74,999',
          BETWEEN_75_AND_100: 'Trong khoảng từ $75,000 đến $99,999',
          BETWEEN_100_AND_150: 'Trong khoảng từ $100,000 đến $149,999',
          MORE_THAN_150: '$150,000 hoặc nhiều hơn',
          NON_DISCLOSURE: 'Không trả lời',
        },
      },
      HOUSEHOLD: {
        LABEL: 'Có bao nhiêu thành viên, người lớn và trẻ con trong gia đình của bạn, bao gồm cả bạn?',
        VALUES: {
          ONE: '1',
          TWO: '2',
          THREE: '3',
          FOUR: '4',
          FIVE: '5',
          SIX_OR_MORE: '6 hoặc hơn thế',
          NON_DISCLOSURE: 'Không trả lời',
        },
      },
      VEHICLES: {
        LABEL:
          'Có bao nhiêu chiếc ô tô có sẵn để sử dụng cho các thành viên trong gia đình của bạn, bao gồm cả xe của bạn?',
        VALUES: {
          ZERO: '0',
          ONE: '1',
          TWO: '2',
          THREE: '3',
          FOUR_OR_MORE: '4 hoặc hơn thế',
          NON_DISCLOSURE: 'Không trả lời',
        },
      },
      BICYCLES: {
        LABEL: 'Các thành viên trong gia đình bạn có bao nhiêu chiếc xe đạp để sử dụng, kể cả xe đạp của bạn?',
        VALUES: {
          ZERO: '0',
          ONE: '1',
          TWO: '2',
          THREE: '3',
          FOUR_OR_MORE: '4 hoặc hơn',
          NON_DISCLOSURE: 'Không trả lời',
        },
      },
      NEIGHBORHOOD: {
        LABEL: 'Bạn sống ở khu vực lân cận nào của Thành phố?',
        NEIGHBORHOOD_NAMES: neighborhoods,
        VALUES: {
          OTHER: 'Các địa điểm khác',
          NON_DISCLOSURE: 'Không trả lời',
        },
      },
    },
  },
};

export default content;
