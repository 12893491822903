import neighborhoods from './neightborhoods';

const content = {
  TITLE: 'SACRAMENTO KEV NPAJ TSEEM CEEB RAU TSHEB THAUJ MUS LOS',
  LANGUAGE_TEXT: {
    TITLE: 'Please Select Your Language',
    LANGUAGE_LIST: {
      english: 'English',
      spanish: 'Español',
      chinese: '汉语',
      hmong: 'Lus Hmoob',
      vietnamese: 'tiếng Việt',
    },
  },
  WELCOME_TEXT: {
    TEXT: (
      <>
        <p>
          Yam dab tsi muaj tseem ceeb tshaj rau koj mus rau hor au ntawd? Puas yog lub Nroog Sacramento, piv txwv lis,
          ceem tseeb:
        </p>

        <ul>
          <li>
            Nqis peev txhawm hor au tej kev tsheb mus mus los los rau cov neeg txom nyem thiab rau ntau lwm haiv neeg
            los nyob ua ke?
          </li>
          <li>Txhim tsam luam ob sab ntug kev tsheb rau tej qhov uas tseem tsis tau muaj?</li>
          <li>Txhaws tej qhov tais kev?</li>
          <li>Kho kev zoo kom tsis muaj tsheb sib tsoo?</li>
        </ul>

        <p>
          Kev Npaj Tseem Ceeb los Tsim Kho Kev Tsheb Mus Los yuav paub meej txog txoj twg raug txhawm kho rau lub Nroog
          tseem ceeb, thiab peb nug txog koj kev pab maj.
        </p>

        <p>Sacramento dav tshaj lis 100 mais ib puag ncig xwm faab thiab tau tsim ua mus siab tshaj:</p>

        <ul>
          <li>1,800 mais ntawm cov kev tsheb</li>
          <li>250 mais ntawm kab kev tsheb tseg rau tsheb kauj vab</li>
          <li>78 mais ntawm txoj me kev mus ko taw faib rau suav dawg taug</li>
          <li>43,000 kev tsheb taws teeb</li>
        </ul>

        <p>
          Nws yuav siv nyiaj txiag muaj txog lis $5 lab los kho thiab txhwm tsa tag nrho cov kev hauv lub nroog, ob sab
          ntug kev, kab kev rau tsheb kauj vab, choj, thiab ua cov phiaj ntawv qhia kev rau ntawm tej ntug kev. Txawm
          tsuas siv muaj txog lis $42 lab nyiaj rau tej kev tsheb mus rau ib lub xyoo xwb, tiam si yuav siv lis 100 lub
          xyoo los ua txhwm tsa rau peb lub haum phiaj uas peb muaj tam sim no. Yam dab tsis lub Nroog yuav pib ua, ua
          ntej?
        </p>

        <p>
          Xav paub ntau tshaj txog xwm txheej, thov caw mus saib{' '}
          <a target='_blank' rel='noreferrer' href='http://www.sactransportation.org/'>
            http://sactransportation.org/
          </a>
          .
        </p>
      </>
    ),
    START: 'PIB',
  },
  STEPPER_TEXT: {
    BUDGET: 'NYIAJ TXIAG',
    OUTCOME: 'THAUM KAWG',
    SUCCESS: 'XA MUS',
  },
  ZIPCODE_TEXT: 'Thov Sau Koj Lub Tsev Cov Npawb ZIP Code',
  BUDGET_TEXT: {
    DESCRIPTION_TEXT: (
      <>
        <p>
          Siv tus txav txav hauv qab ko los hais qhia yam dab tsi yog yam tseem ceeb tshaj rau koj rau cov kev tsheb mus
          mus los los hauv Nroog Sacramento.Lub Nroog tsuas muaj me ntsis nyiaj xwb, li ntawd peb yuav txhawm tsis tau
          txhua tsav txhua yam. Pev yuav tsum muaj kev xaiv nyuaj. Koj tuaj yeem xaiv ntawm tus npawb 6 nws tus nqis tau
          piav qhia txog hauv qab no lossis sau ntawm koj tus peev xwm.
        </p>
        <p>
          <b>
            Koj qhov kev xa no yuav mus hais qhia rau lub Nroog qhov dej num tseem ceeb npaum lis cas thiab yuav nrhiav
            nyiaj txiag lis cas rau yav tom ntej sai sai no.
          </b>
          Yog tia koj rov muab tsim kho, piv txwv lis, lub Nroog tej thaum tsuas muaj nyiaj tsawg tsawg los txhwm tsa
          rau cov tshuab thev naus laus zis xwb. Yog tia koj muab saib qhov kev pab cuam noj qab hau huv thiab txhwm tsa
          kom tej huab cua huv siab, Lub Nroog yuav tau txhwm tsa los ua tej haujlwm no sai sai tsis ntev yav tom ntej
          no.
        </p>
        <p>
          <i>
            Thov nco ntsoov tia Caij Tsheb mus los hauv cheeb tsam - tsis yog hauv lub Nroog Sacramento - khiav ua hauj
            lwm rau cov tsheb npav thiab tsheb ciav hlau hauv Nroog.Caij Tsheb Mus Los Hauv Cheeb Tsam nws muaj nws ib
            cov nyiaj, lis ntaws thiaj vim lis cas kev pab cuam thauj mus los thiaj lis tsis muab tus nqi qhia tawm rau
            ntawm no. Hais qhia koj kev xav nrog rau qhov caij tsheb mus los hauv cheeb tsam, thov mus saib ntxiv rau:{' '}
            <a href='http://www.sacrt.com/customerfeedback/' rel='noreferrer' target='_blank'>
              http://www.sacrt.com/customerfeedback/
            </a>
          </i>
        </p>
      </>
    ),
    PROGRESS_TITLE: 'Txhua Yam Tseem Ceeb Tag Nrho',
    CATEGORIES: {
      access: {
        TITLE: 'Kev sib txua mus rau cov thaj chaw',
        DESCRIPTION:
          'Muab kev sib txua rau cov thaj chaw ua ke saib muaj tseem ceeb kuj txhai tau tias muab cov haujlwm ntawd saib muaj hnuj nqis ua tau suav daws yooj yim taug kev mus kawm ntawv, mus ua si ntawm cov vaj chaw ua si, cov chaw tso ntawv, zej zos lub tsev sib tham sab laj txog haujlwm hauv zos, kiab khw hauv zej zos, chaw ua haujlwm, thiab lwm qhov chaw thiab kuj suav nrog sau cov ob sab ntug kev tsheb, chaws hla kev tsheb, kev rau tsheb kauj vab mus, txhim tsa tej choj thiab kev tsheb mus hauv zej zos.',
        PLACES_TEXT: 'Qhov chaw twg yuav muab saib muaj tseem ceeb? Xaiv peb qhov chaw muaj tseem ceeb tshaj:',
        PLACES: {
          COMMUNITY_CENTERS: 'Zej zos lub tsev sib tham ua haujlwm rau hauv zos',
          JOBS: 'Cov chaw ua haujlwm',
          LIBRARIES: 'Cov chaw tso ntawv',
          PARKS: 'Cov vaj chaw ua si',
          SCHOOLS: 'Cov tsev kawm ntawv',
          SHOPS: 'Cov kiab khw yuav khoom',
          OTHER: 'Lwm qhov chaw',
        },
      },
      climate: {
        TITLE: 'Kev Saib Xyuas Huab Cua Huv thiab Kev Noj Qab Hau Huv',
        DESCRIPTION:
          'Kev saib xyuas cov huab cua zoo thiab kev noj qab haus huv yuav txhais hais tias muab saib cov hauj lwm ntawd muaj tseem ceeb txhawb kom taug kev mus ko taw. caij tsheb kauj vab, thiab caij cov tsheb khiav hlauv taws xob mus rau ntawm ob sab ntug kev, tej kev tshuam tseg cia mus ko taws, kab kev tseg rau caij tsheb kauj vab, nkag mus caij tsheb mus ub mus no, lossis chaw sam hluav taws xob rau tsheb.',
      },
      equity: {
        TITLE: 'Vaj Huam Sib Luag',
        DESCRIPTION:
          'Muab kev vaj huam sib luag saib muaj hnuj qhis kuj txhais tau tias txhwm tsa tej kev tsheb mus los hauv zej zos, qhov no yog ib yam uas tsis tau pom muaj dua nyob rau hauv keeb kwm, tsis tau muaj tej khoom zoo, lossis ntau hom haiv neeg tseem txom yem.Qhov no suav nrog rau cov ob sab ntug kev tsheb, cov chaw hla kev tsheb rau neeg mus ko taw, cov kev tsheb kauj vab, rov tsim kho dua, ntug kev tshiab, lossis txhwm tsa kev sib txuas mus rau cov tsheb npav thiab tsheb ciav hlau.',
      },
      maintenance: {
        TITLE: 'Rov tsim kho dua',
        DESCRIPTION:
          'Muab qhov rov tsim kho dua saib muaj hnuj nqis txhai tau tia kho cov qhov tais kev tsheb uas pob pob, rov kho cov kev tsheb hauv zos thiab cov kev mus ko taw / caij tsheb kauj vab,  thiab txhab cov ntug kev tsheb ntawm cov chaw ki tseg rau cov neeg mus ko taw hla kev tsheb.',
      },
      safety: {
        TITLE: 'Nyab xeeb',
        DESCRIPTION:
          'Muab kev nyab xeem saib muaj tseem ceeb txhai tau tias muab cov haujlwm txhawm kho tej chaw muaj ntau lub tsheb sib tsoo thiab muaj ntau tus neeg tuag rau thaj chaw ntaw thiab kuj muab saib cov lus hais qhia ntawm tej ntug kev ntawd saib muaj tseem ceeb, kes kab kev ntawm tej ntug kev, cov chaw hla kev mus ko taw hla kev tsheb, kab kev caij tsheb kauj vab, teeb cig qhia kev tsheb, teeb cig qhia rau cov neeg taug kev mus ko taw, lossis caij tsheb ceev thiab txo kab kev tsheb.',
      },
      technology: {
        TITLE: 'Thev no lo ntsi',
        DESCRIPTION:
          'Muab Thev no lo ntsis saib muaj tseem ceeb txhai tau tia txhawm kho cov phiaj ntawv qhia kev thiab ua cov phiaj ceeb toom qhia thiab txhab cov phiaj qhia rau neeg taug kev thiab lwm yam khoom nyab xeeb.',
      },
      other: {
        TITLE: 'Lwm yam',
        DESCRIPTION: 'Tej yam uas peb tsis tau hais txog?',
        INPUT_PLACEHOLDER: 'Sau koj tus nqi rau qhov no.',
      },
    },
    BUDGET_WARNING:
      'Yuav tsum paub meej koj tej kev txhab tseem ceeb mus txog ncua 100% ua ntej thaum xa koj tus nqis.',
    BUTTON_TEXT: 'SAIB THAUM KAWG',
  },
  OUTCOME_TEXT: {
    MORE_PRIORITY_TITLE: 'THAUM KAWG: Ua lawv raw koj tus qhi, koj hais qhia kom lub nroog  muaj tseem ceeb...',
    LESS_PRIORITY_TITLE: 'thiab siv nyiaj txiag tsawg rau:',
    MORE_RECOMMENDED_TITLE:
      'Koj tsis txhob xaiv ib tus qhi tseem ceeb twg.Koj hais qhia rau lub Nroog qhov tseem ceeb...',
    CATEGORIES: {
      access:
        'Muab kev sib txua rau cov thaj chaw ua ke: Cov haujlwm ua rau suav daws yooj yim taug kev mus kawm ntawv, mus ua si ntawm cov vaj chaw ua si, cov chaw tso ntawv, zej zos lub tsev sib tham sab laj txog haujlwm hauv zos, kiab khw hauv zej zos, chaw ua haujlwm, thiab mus rau lwm qhov chaw.',
      climate:
        'Cov huab cua huv thiab noj qab hau uv: cov haujlwm txhawb kom taug kev mus ko taws, cej tsheb kauj vab thiab caij cov tsheb qhiav hlauv taws xob.',
      equity:
        'Kev Muaj Vaj Huam Sib Luag:  Cov haujlwm hauv zej zos uas tsis tau pom muaj muaj nqis peeb los txhwm tsa hauv keeb kwm yav tas los, tsis tau muaj tej khoom zoo, lossis ntau yam haiv neeg sib txawv tseem txom yem.',
      maintenance:
        'Rov tsim kho dua: Tej haujlwm los txhaws cov qhov tais pob pob hauv kev tsheb, kho dua kev tsheb thiab kev mus ko taws hauv zos, thiab txhab tej kaum ntug kev tsheb ntawm tej chaw uas tseg hla kev tsheb mus ko taw.',
      safety:
        'Nyab xeeb: Tej haujlwm uas nyob ntawm tej chaw uas muaj tsheb sib tsoo hnyav thiab tshwm sim tau muaj neeg tuag.',
      technology:
        'Thev nos los ntsis: Cov hauj lwm txhwm tsa cov phiaj qhia kev thiab hais qhia ua hauj lwm thiab ua ntxiv cov phiaj hais qhia rau cov neeg mus ko taw thiab lwm yam khoom nyab xeeb',
      other: 'Lwm yam ntxiv',
    },
    BOTTOM_PAGE_TEXT: (
      <div>
        THAUM KAWG: Ua raw lis tus txav txav tus nqis.Nyem ntawm lub pob TUS NQI hauv qab no los hloov pauv qhov kawg.
      </div>
    ),
    BUDGET_BUTTON: 'TUS NQI',
    SUBMIT_BUTTON: 'XA MUS',
  },
  SUCCESS_TEXT: {
    TITLE: 'Ua Tsaug!',
    SUBTITLE: 'Koj tej yam tseem ceeb twb tau xa mus tiav tag lawm.Peb zoo siab koj kev koom tes nrog rau peb.',
    SUBSCRIPTION_TEXT:
      'Thov nrog peb koom tes mus ntxiv! Nyem ntawm lub pob hauv qab ko mus sau rau hauv peb lub email npe ntaw.',
    SUBSCRIPTION_INPUT_TEXT: 'Sau koj lub email chaw xa tseg rau hauv no:',
    SUBSCRIPTION_INPUT_ERROR: 'Thov sau email siv tau',
    SUBSCRIPTION_SUCCESS_TEXT: 'Email rau npe tiav lawm!',
    OPTIONAL_TITLE: 'Cov Lus Nug Tau Xaiv Tseg',
    OPTIONAL_SUBTITLE:
      'Thov mus teb raw lcov lus nug no kom pab peb paub meej tiv tauj txog cov qhauv sawv cev rau cov pej xeem hauv Nroog.Koj tej lus teb yuav tsis hais qhia koj yog leej twg.',
    SUBSCRIBE_BUTTON: 'Sau npe yuav ua',
    SUBMIT_BUTTON: 'XA MUS',
    SUBMISSION_SUCCESS_TEXT: 'Ua tsaug rau koj! Koj cov lus teb tau muaj xa.',
    FORM: {
      GENDER: {
        LABEL: 'Kuv Paub meej yog:',
        VALUES: {
          FEMALE: 'Poj niam (kuj xam txog tus poj niam uas nws hloov cev ntawm txiv neej los)',
          MALE: 'Txiv neej (kuj xam txog tus txiv neej uas nws hloov cev ntawm poj niam los)',
          GENDER_DIVERSE: 'Tub los ntxhais sib txawv/ Tsis paub meej xyov yog tub los ntxhais',
          OTHER: 'Lwm yam',
          NON_DISCLOSURE: 'Zoo tshaj tsis txhob hais txog',
        },
        PLEASE_SPECIFY_LABEL: 'thov qhia meej',
      },
      ETHNICITY: {
        LABEL: 'Kuv paub meej kuv haiv neeg:',
        VALUES: {
          ASIAN: 'Neeg Axias',
          BLACK: 'Neeg Meskas Dub/ Neeg Meskas Aflakas',
          HISPANIC: 'Neeg Meev/ Neeg Latin',
          NATIVE_AMERICAN: 'Tseem Neeg Meskas',
          PACIFIC_ISLANDER: 'Neeg Nyob Pacific Islander',
          WHITE: 'Neeg Tawv Dawb',
          OTHER: 'Lwm yam',
          NON_DISCLOSURE: 'Zoo tshaj tsis txhob hais txog',
        },
        PLEASE_SPECIFY_LABEL: 'thov qhia meej',
      },
      AGE: {
        LABEL: 'Koj muaj hnub nyug li cas?',
        VALUES: {
          UNDER_18: 'Hluas tshaj 18',
          BETWEEN_18_AND_25: '18-24',
          BETWEEN_25_AND_35: '25-34',
          BETWEEN_35_AND_45: '35-44',
          BETWEEN_45_AND_55: '45-54',
          BETWEEN_55_AND_65: '55-64',
          OLDER_65: '65+',
          NON_DISCLOSURE: 'Zoo tshaj tsis txhob hais txog',
        },
      },
      LANGUAGE: {
        LABEL: 'Koj tham yam lus dab tsis thaum nyob hauv tseb?',
        VALUES: {
          CHINESE: 'Lus Suav',
          ENGLISH: 'Lus Askiv',
          HMONG: 'Lus Hmoob',
          SPANISH: 'Lus Spanish',
          VIETNAMESE: 'Lus Nyab Laj',
          OTHER: 'Lwm yam',
          NON_DISCLOSURE: 'Zoo tshaj tsis txhob hais txog',
        },
        PLEASE_SPECIFY_LABEL: 'thov qhia meej',
      },
      LGBTQ: {
        LABEL:
          'Koj puas pom koj tus kheej yog ib tus cov pab pawg neeg poj niam nyiam poj niam, txiv neej nyiam hlub txiv neej, ib nrab poj niam ib nrab txiv neej, poj niam hloov cev ua txiv neej lossis  txiv neej ho hloov cev ua poj niam, thiab/ los sis cov neeg hais lus poj niam mos mos (LGBTQ)',
        VALUES: {
          NO: 'Tsis pom',
          YES: 'Pom',
          NON_DISCLOSURE: 'Zoo tshaj tsis txhob hais txog',
        },
      },
      DISABILITY: {
        LABEL:
          'Hauv koj tsev neeg puas muaj ib tus neeg twg tau raug tuag tes tuag taw uas ua tsis tau hauj lwm thiab mus tsis tau kev, xam txog koj twb si?',
        VALUES: {
          NO: 'Tsis pom',
          YES: 'Pom',
          NON_DISCLOSURE: 'Zoo tshaj tsis txhob hais txog',
        },
      },
      INCOME: {
        LABEL: 'Hauv koj lub tsev ib xyoo khwv tau nyiaj txiag tau npaum lis cas?',
        VALUES: {
          UNDER_15: 'Tsawg tshaj $15,000',
          BETWEEN_15_AND_30: 'Muaj txog $15,000 mus rau $29,999',
          BETWEEN_30_AND_50: 'Muaj txog $30,000 mus rau $49,999',
          BETWEEN_50_AND_75: 'Muaj txog $50,000 mus rau $74,999',
          BETWEEN_75_AND_100: 'Muaj txog $75,000 mus rau $99,999',
          BETWEEN_100_AND_150: 'Muaj txog $100,000 mus rau $149,999',
          MORE_THAN_150: '$150,000 los sis ntau tshaj',
          NON_DISCLOSURE: 'Zoo tshaj tsis txhob hais txog',
        },
      },
      HOUSEHOLD: {
        LABEL: 'Muaj peg tsawg tus tib neeg, laus thiab menyuam nyob hauv koj tsev neeg, xam txog koj thiab?',
        VALUES: {
          ONE: '1',
          TWO: '2',
          THREE: '3',
          FOUR: '4',
          FIVE: '5',
          SIX_OR_MORE: '6 los sis ntau tshaj',
          NON_DISCLOSURE: 'Zoo tshaj tsis txhob hais txog',
        },
      },
      VEHICLES: {
        LABEL:
          'Muaj peg tsawg lub tsheb tseem tab tom siv lo ntawm cov neeg hauv koj tsev neeg, xam txog koj lub tsheb ua ke tib si?',
        VALUES: {
          ZERO: '0',
          ONE: '1',
          TWO: '2',
          THREE: '3',
          FOUR_OR_MORE: '4 los sis ntau tshaj',
          NON_DISCLOSURE: 'Zoo tshaj tsis txhob hais txog',
        },
      },
      BICYCLES: {
        LABEL:
          'Muaj peg tsawg lub tsheb kauj vab tab tom siv lo ntawm koj cov neeg hauv koj tsev neeg, xam txog koj lub tsheb ua ke tib si?',
        VALUES: {
          ZERO: '0',
          ONE: '1',
          TWO: '2',
          THREE: '3',
          FOUR_OR_MORE: '4 los sis ntau tshaj',
          NON_DISCLOSURE: 'Zoo tshaj tsis txhob hais txog',
        },
      },
      NEIGHBORHOOD: {
        LABEL: 'What neighborhood of the City do you live in?',
        NEIGHBORHOOD_NAMES: neighborhoods,
        VALUES: {
          OTHER: 'Lwm qhov chaw',
          NON_DISCLOSURE: 'Zoo tshaj tsis txhob hais txog',
        },
      },
    },
  },
};

export default content;
