const neighborhoods = [
  'Airport',
  'Alkali Flat',
  'Arden Fair',
  'Avondale',
  'Belvedere',
  'Ben Ali',
  'Boulevard Park',
  'Brentwood',
  'Cal Expo',
  'Campus Commons',
  'Cannon Industrial',
  'Carleton Tract',
  'Central Oak Park',
  'College Town',
  'College/Glen',
  'Colonial Manor',
  'Colonial Village',
  'Creekside',
  'Curtis Park',
  'Del Paso Heights',
  'Del Paso Park',
  'Depot Park',
  'Dos Rios Triangle',
  'Downtown',
  'East Del Paso Heights',
  'East Sacramento',
  'Elder Creek',
  'Elmhurst',
  'Erikson Industrial',
  'Fairgrounds',
  'Florin Fruitridge Industrial',
  'Freeport Manor',
  'Fruitridge Manor',
  'Gardenland',
  'Gateway Center',
  'Gateway West',
  'Glen Elder',
  'Glenwood Meadows',
  'Gold Course Terrace',
  'Granite Regional Park',
  'Greenbriar',
  'Greenhaven',
  'Hagginwood',
  'Hasen Park',
  'Heritage Park',
  'Hollywood Park',
  'Johnson Business Park',
  'Johnson Heights',
  'Land Park',
  'Lawrence Park',
  'Mangan Park',
  'Mansion Flats',
  'Marshall School',
  'Meadowview',
  'Med Center',
  'Metro Center',
  'Midtown/Winn Park/Capitol Avenue',
  'Morrison Creek',
  'Natomas Corporate Center',
  'Natomas Creek',
  'Natomas Crossing',
  'Natomas Park',
  'New Brighton',
  'New Era Park',
  'Newton Booth',
  'Noralto',
  'North City Farms',
  'North Oak Park',
  'Northgate',
  'Northpointe',
  'Norwood Tech',
  'Norwood/I-80',
  'Oak Knoll',
  'Old North Sac',
  'Old Sacramento',
  'Parker Homes',
  'Parkway',
  'Pell/Main Industrial',
  'Pocket',
  'Point West',
  'Power Ridge',
  'Raley Industrial',
  'Ramona Village',
  'Regency Park',
  'Richardson Village',
  'Richmond Grove',
  'River Gardens',
  'River Park',
  'Robla',
  'RP-Sports Complex',
  'SCC',
  'Sierra Oaks',
  'South City Farms',
  'South Hagginwood',
  'South Land Park',
  'South Natomas',
  'South Oak Park',
  'Southeast Village',
  'Southern Pacific/Richards',
  'Southside Park',
  'Strawberry Manor',
  'Sundance Lake',
  'Swanston Estates',
  'Tahoe Park',
  'Tahoe Park East',
  'Tahoe Park South',
  'Tallac Village',
  'Upper Land Park',
  'Valley Hi/North Laguna',
  'Valleyview Acres',
  'Village 12',
  'Village 14',
  'Village 5',
  'Village 7',
  'Village Green',
  'West Del Paso Heights',
  'West Tahoe Park',
  'Westlake',
  'Willowcreek',
  'Wills Acres',
  'Woodbine',
  'Woodlake',
  'Youngs Heights',
];

export default neighborhoods;
