import neighborhoods from './neightborhoods';

const content = {
  TITLE: 'Sacramento Transportation Priorities Plan',
  LANGUAGE_TEXT: {
    TITLE: 'Please Select Your Language',
    LANGUAGE_LIST: {
      english: 'English',
      spanish: 'Español',
      chinese: '汉语',
      hmong: 'Lus Hmoob',
      vietnamese: 'tiếng Việt',
    },
  },
  WELCOME_TEXT: {
    TEXT: (
      <>
        <p>What’s most important to you for getting around? Should the City of Sacramento, for example, prioritize:</p>

        <ul>
          <li>Making transportation investments in low-income and communities of color?</li>
          <li>Building sidewalks where there are none?</li>
          <li>Filling potholes?</li>
          <li>Reducing crashes?</li>
        </ul>

        <p>
          The Transportation Priorities Plan will identify which transportation improvements the City should prioritize,
          and we’re asking for your help.
        </p>

        <p>Sacramento spans more than 100 square miles and is made up of more than:</p>

        <ul>
          <li>1,800 miles of streets</li>
          <li>250 miles of bike lanes</li>
          <li>78 miles of shared use paths</li>
          <li>43,000 streetlights</li>
        </ul>

        <p>
          It would cost about $5 billion to fix and improve all of the City’s roads, sidewalks, bike lanes, bridges, and
          traffic signals. With only an average of $42 million per year for transportation, though, it would take over
          100 years to build out what we currently have planned. What should the City do first?
        </p>

        <p>
          For more information, please visit{' '}
          <a target='_blank' rel='noreferrer' href='http://www.sactransportation.org/'>
            http://sactransportation.org/
          </a>
          .
        </p>
      </>
    ),
    START: 'Start',
  },
  STEPPER_TEXT: {
    BUDGET: 'Budget',
    OUTCOME: 'Outcome',
    SUCCESS: 'Submit',
  },
  ZIPCODE_TEXT: 'Please enter your home ZIP code',
  BUDGET_TEXT: {
    DESCRIPTION_TEXT: (
      <>
        <p>
          Use the sliders below to share what’s most important to you for transportation in Sacramento. The City has
          limited funding, so we can’t prioritize everything. We have to make hard choices. You can choose from the 6
          values described below or write in your own.
        </p>
        <p>
          <b>
            Your submission here will inform how the City prioritizes projects and what gets funding in the near future.
          </b>{' '}
          If you prioritize maintenance, for example, the City may have less money for technology improvements. If you
          prioritize air quality and health, the City will build those projects sooner rather than later.
        </p>
        <p>
          <i>
            Please note that Regional Transit – not the City of Sacramento – operates buses and light rail in the City.
            Regional Transit has its own budget, which is why transit service is not offered as a value here. To share
            your thoughts with Regional Transit, please visit:{' '}
            <a href='http://www.sacrt.com/customerfeedback/' rel='noreferrer' target='_blank'>
              http://www.sacrt.com/customerfeedback/
            </a>
          </i>
        </p>
      </>
    ),
    PROGRESS_TITLE: 'Overall Prioritization',
    CATEGORIES: {
      access: {
        TITLE: 'Connections to Places',
        DESCRIPTION:
          'Prioritizing connections to places would mean prioritizing projects that make it easier to reach schools, parks, libraries, community centers, local shops, jobs, and other places and include sidewalks, crosswalks, bikeways, bridges and street improvements.',
        PLACES_TEXT: 'What places should have priority? Pick your top three:',
        PLACES: {
          COMMUNITY_CENTERS: 'Community centers',
          JOBS: 'Jobs',
          LIBRARIES: 'Libraries',
          PARKS: 'Parks',
          SCHOOLS: 'Schools',
          SHOPS: 'Shops',
          OTHER: 'Other',
        },
      },
      climate: {
        TITLE: 'Air Quality and Health',
        DESCRIPTION:
          'Prioritizing air quality and health would mean prioritizing projects that encourage walking, biking, and electric vehicles such as sidewalks, crosswalks, bike lanes, access to transit, or electric vehicle charging stations.',
      },
      equity: {
        TITLE: 'Equity',
        DESCRIPTION:
          'Prioritizing equity would mean improving transportation conditions in communities that have not seen investment historically, lack basic infrastructure, or are low-income communities of color. This could include sidewalks, crosswalks, bikeways, maintenance, new pavement, or improved connections to buses and trains.',
      },
      maintenance: {
        TITLE: 'Maintenance',
        DESCRIPTION:
          'Prioritizing maintenance would mean fixing potholes, repaving streets and walking/biking paths, and adding curb ramps at crosswalks.',
      },
      safety: {
        TITLE: 'Safety',
        DESCRIPTION:
          'Prioritizing safety would mean prioritizing improvements where the most serious and deadly collisions occur and could include signs, pavement striping, crosswalks, bike lanes, traffic signals, pedestrian signals, or speed and lane reductions.',
      },
      technology: {
        TITLE: 'Technology',
        DESCRIPTION:
          'Prioritizing technology would mean upgrading traffic signals and signal operation and adding pedestrian signals and other safety equipment.',
      },
      other: {
        TITLE: 'Other',
        DESCRIPTION: 'Something we missed?',
        INPUT_PLACEHOLDER: 'Enter your own value here',
      },
    },
    BUDGET_WARNING: 'Please be sure your priorities add to 100% before submitting your values.',
    BUTTON_TEXT: 'View Outcome',
  },
  OUTCOME_TEXT: {
    MORE_PRIORITY_TITLE: 'OUTCOME: Based on your values, you recommend the City prioritize…',
    LESS_PRIORITY_TITLE: 'And spend less on:',
    MORE_RECOMMENDED_TITLE:
      'You have chosen not to prioritize any one value. You are recommending the City prioritize…',
    CATEGORIES: {
      access:
        'Connections to places: projects that make it easier to reach schools, parks, libraries, community centers, local shops, jobs, and other places.',
      climate: 'Air quality and health: projects that encourage walking, biking, and electric vehicles.',
      equity:
        'Equity:  projects in communities that have not seen investment historically, lack basic infrastructure, or are low-income communities of color.',
      maintenance:
        'Maintenance: projects that fix potholes, repave streets and paths, and add curb ramps at crosswalks.',
      safety: 'Safety: projects where the most serious and deadly collisions occur.',
      technology:
        'Technology: projects that upgrade traffic signals and signal operation and add pedestrian signals and other safety equipment.',
      other: 'Something else',
    },
    BOTTOM_PAGE_TEXT: (
      <div>OUTCOME: Based on sliders for each value. Click VALUES button below to change the outcomes.</div>
    ),
    BUDGET_BUTTON: 'Values',
    SUBMIT_BUTTON: 'Submit',
  },
  SUCCESS_TEXT: {
    TITLE: 'Thank you!',
    SUBTITLE: 'Your priorities have been successfully submitted. We appreciate your participation.',
    SUBSCRIPTION_TEXT: 'Please stay engaged! Click the button below to sign up for our email list.',
    SUBSCRIPTION_INPUT_TEXT: 'Enter your email address here',
    SUBSCRIPTION_INPUT_ERROR: 'Please enter a valid email',
    SUBSCRIPTION_SUCCESS_TEXT: 'Email subscribed',
    OPTIONAL_TITLE: 'Optional Questions',
    OPTIONAL_SUBTITLE:
      'Please answer the following questions to help ensure we reach a representative sample of City residents. Your answers will remain anonymous.',
    SUBSCRIBE_BUTTON: 'Subscribe',
    SUBMIT_BUTTON: 'Submit',
    SUBMISSION_SUCCESS_TEXT: 'Thank you! Your answers were submitted.',
    FORM: {
      GENDER: {
        LABEL: 'I identify as:',
        VALUES: {
          FEMALE: 'Female (including Trans Female)',
          MALE: 'Male (including Trans Male)',
          GENDER_DIVERSE: 'Gender Diverse/Non-Binary',
          OTHER: 'Other',
          NON_DISCLOSURE: 'Prefer not to say',
        },
        PLEASE_SPECIFY_LABEL: 'Please specify',
      },
      ETHNICITY: {
        LABEL: 'I identify my ethnicity as:',
        VALUES: {
          ASIAN: 'Asian',
          BLACK: 'Black/African American',
          HISPANIC: 'Hispanic/Latinx',
          NATIVE_AMERICAN: 'Native American',
          PACIFIC_ISLANDER: 'Pacific Islander',
          WHITE: 'White',
          OTHER: 'Other',
          NON_DISCLOSURE: 'Prefer not to say',
        },
        PLEASE_SPECIFY_LABEL: 'Please specify',
      },
      AGE: {
        LABEL: 'What is your age?',
        VALUES: {
          UNDER_18: 'Under 18',
          BETWEEN_18_AND_25: '18-24',
          BETWEEN_25_AND_35: '25-34',
          BETWEEN_35_AND_45: '35-44',
          BETWEEN_45_AND_55: '45-54',
          BETWEEN_55_AND_65: '55-64',
          OLDER_65: '65+',
          NON_DISCLOSURE: 'Prefer not to say',
        },
      },
      LANGUAGE: {
        LABEL: 'What language do you speak at home?',
        VALUES: {
          CHINESE: 'Chinese',
          ENGLISH: 'English',
          HMONG: 'Hmong',
          SPANISH: 'Spanish',
          VIETNAMESE: 'Vietnamese',
          OTHER: 'Other',
          NON_DISCLOSURE: 'Prefer not to say',
        },
        PLEASE_SPECIFY_LABEL: 'Please specify',
      },
      LGBTQ: {
        LABEL:
          'Do you consider yourself a member of the Lesbian, Gay, Bisexual, Transgender, and/or Queer (LGBTQ) community?',
        VALUES: {
          NO: 'No',
          YES: 'Yes',
          NON_DISCLOSURE: 'Prefer not to say',
        },
      },
      DISABILITY: {
        LABEL: 'Does anyone in your household have a disability that affects their mobility, including yourself?',
        VALUES: {
          NO: 'No',
          YES: 'Yes',
          NON_DISCLOSURE: 'Prefer not to say',
        },
      },
      INCOME: {
        LABEL: 'What is your annual household income?',
        VALUES: {
          UNDER_15: 'Under $15,000',
          BETWEEN_15_AND_30: 'Between $15,000 and $29,999',
          BETWEEN_30_AND_50: 'Between $30,000 and $49,999',
          BETWEEN_50_AND_75: 'Between $50,000 and $74,999',
          BETWEEN_75_AND_100: 'Between $75,000 and $99,999',
          BETWEEN_100_AND_150: 'Between $100,000 and $149,999',
          MORE_THAN_150: '$150,000 or more',
          NON_DISCLOSURE: 'Prefer not to say',
        },
      },
      HOUSEHOLD: {
        LABEL: 'How many people, adults and children live in your household, including yourself?',
        VALUES: {
          ONE: '1',
          TWO: '2',
          THREE: '3',
          FOUR: '4',
          FIVE: '5',
          SIX_OR_MORE: '6 or more',
          NON_DISCLOSURE: 'Prefer not to say',
        },
      },
      VEHICLES: {
        LABEL: 'How many automobiles are available for use by members of your household, including your own vehicle?',
        VALUES: {
          ZERO: '0',
          ONE: '1',
          TWO: '2',
          THREE: '3',
          FOUR_OR_MORE: '4 or more',
          NON_DISCLOSURE: 'Prefer not to say',
        },
      },
      BICYCLES: {
        LABEL: 'How many bicycles are available for use by members of your household, including your own bicycle?',
        VALUES: {
          ZERO: '0',
          ONE: '1',
          TWO: '2',
          THREE: '3',
          FOUR_OR_MORE: '4 or more',
          NON_DISCLOSURE: 'Prefer not to say',
        },
      },
      NEIGHBORHOOD: {
        LABEL: 'What neighborhood of the City do you live in?',
        NEIGHBORHOOD_NAMES: neighborhoods,
        VALUES: {
          OTHER: 'Other',
          NON_DISCLOSURE: 'Prefer not to say',
        },
      },
    },
  },
};

export default content;
