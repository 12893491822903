import neighborhoods from './neightborhoods';

const content = {
  TITLE: 'Plan de prioridades de transporte de la ciudad de Sacramento',
  LANGUAGE_TEXT: {
    TITLE: 'Please Select Your Language',
    LANGUAGE_LIST: {
      english: 'English',
      spanish: 'Español',
      chinese: '汉语',
      hmong: 'Lus Hmoob',
      vietnamese: 'tiếng Việt',
    },
  },
  WELCOME_TEXT: {
    TEXT: (
      <>
        <p>
          ¿Qué es lo más importante para desplazarse? ¿Debería la ciudad de Sacramento, por ejemplo, dar prioridad en lo
          siguiente:
        </p>

        <ul>
          <li>¿Invertir en transporte en comunidades de bajos ingresos y de color?</li>
          <li>¿Construir aceras donde no las hay?</li>
          <li>¿Rellenar baches?</li>
          <li>¿Reducir el número de accidentes?</li>
        </ul>

        <p>
          El Plan de Prioridades de Transporte identificará las mejoras de transporte que debe priorizar la ciudad, y
          por esta razón se pide su ayuda.
        </p>

        <p>Sacramento se extiende por más de 100 millas cuadradas y se compone de más de lo siguiente:</p>

        <ul>
          <li>1.800 millas de calles</li>
          <li>250 millas de carriles para bicicletas</li>
          <li>78 millas de caminos de uso compartido</li>
          <li>43.000 farolas</li>
        </ul>

        <p>
          Costaría más o menos 5 billones de dólares arreglar y mejorar las carreteras, aceras, carriles bici, puentes y
          señales de tráfico de la ciudad. Sin embargo, con la ciudad invirtiendo un promedio de 42 millones de dólares
          al año para el transporte, tomaría más de 100 años para construir lo que actualmente se ha planeado. ¿Qué
          debería la ciudad hacer primero?
        </p>

        <p>
          Para más información, por favor visite{' '}
          <a target='_blank' rel='noreferrer' href='http://www.sactransportation.org/'>
            http://sactransportation.org/
          </a>
          .
        </p>
      </>
    ),
    START: 'COMENZAR',
  },
  STEPPER_TEXT: {
    BUDGET: 'Presupuesto',
    OUTCOME: 'Resultado',
    SUCCESS: 'Enviar',
  },
  ZIPCODE_TEXT: 'Por favor introduzca el código postal de su domicilio',
  BUDGET_TEXT: {
    DESCRIPTION_TEXT: (
      <>
        <p>
          Utilice los controles deslizantes de abajo para compartir que es más importante para usted referente al
          transporte en Sacramento. La ciudad tiene fondos limitados, así que no podemos priorizar todo. Tenemos que
          tomar decisiones difíciles. Puede elegir entre los 6 valores descritos a continuación o escribir los suyos
          propios.
        </p>
        <p>
          <b>
            Su contestación ayudará a la ciudad a priorizar los proyectos y lo que se financiará en un futuro cercano.{' '}
          </b>
          Si usted da prioridad al mantenimiento, por ejemplo, el Ayuntamiento dispondrá de menos dinero para mejoras
          tecnológicas. Si prioriza la calidad del aire y la salud, la ciudad construirá esos proyectos primero.
        </p>
        <p>
          <i>
            Tenga en cuenta que Regional Transit, y no la ciudad de Sacramento, gestiona los autobuses y el tren ligero
            en la ciudad. Regional Transit tiene su propio presupuesto, por lo que el servicio de tránsito no se
            menciona aquí. Para compartir sus opiniones con Regional Transit, visite:{' '}
            <a href='http://www.sacrt.com/customerfeedback/' rel='noreferrer' target='_blank'>
              http://www.sacrt.com/customerfeedback/
            </a>
          </i>
        </p>
      </>
    ),
    PROGRESS_TITLE: 'Priorización general',
    CATEGORIES: {
      access: {
        TITLE: 'Conexiones con lugares de destino',
        DESCRIPTION:
          'Dar prioridad a las conexiones con los lugares de destino significa dar prioridad a los proyectos que facilitan el acceso a las escuelas, parques, bibliotecas, centros comunitarios, tiendas locales, puestos de trabajo y otros lugares e incluyen aceras, pasos de peatones, carriles bici, puentes y mejoras en las calles.',
        PLACES_TEXT: '¿Qué lugares deberían tener prioridad? Elige tus tres primeros:',
        PLACES: {
          COMMUNITY_CENTERS: 'Centros comunitarios',
          JOBS: 'Empleos',
          LIBRARIES: 'Bibliotecas',
          PARKS: 'Parques',
          SCHOOLS: 'Escuelas',
          SHOPS: 'Tiendas',
          OTHER: 'Otros',
        },
      },
      climate: {
        TITLE: 'Calidad del aire y salud',
        DESCRIPTION:
          'Dar prioridad a la calidad del aire y la salud significa dar prioridad a los proyectos que fomentan los desplazamientos a pie, en bicicleta y en vehículos eléctricos, como son las aceras, los pasos de peatones, los carriles para bicicletas, el acceso al tránsito o a las estaciones de carga de vehículos eléctricos.',
      },
      equity: {
        TITLE: 'Equidad',
        DESCRIPTION:
          'Dar prioridad a la equidad significa mejorar las condiciones de transporte en las comunidades que históricamente no han visto inversión, que carecen de infraestructura básica o que son comunidades de color con bajos ingresos. Esto incluiría aceras, pasos de peatones, carriles para bicicletas, mantenimiento, nuevo pavimento o mejores conexiones con los autobuses y trenes.',
      },
      maintenance: {
        TITLE: 'Mantenimiento',
        DESCRIPTION:
          'Dar prioridad al mantenimiento significa arreglar los baches, repavimentar las calles y los carriles bici y añadir rampas en los pasos de peatones.',
      },
      safety: {
        TITLE: 'Seguridad',
        DESCRIPTION:
          'Dar prioridad a la seguridad significa dar prioridad a las mejoras en los lugares donde se producen las colisiones más graves y mortales, e incluiría señales de tránsito, marcas en el pavimento, cruces peatonales, carriles para bicicletas, señales de tráfico, señales para peatones o reducciones de velocidad y carriles.',
      },
      technology: {
        TITLE: 'Tecnología',
        DESCRIPTION:
          'Dar prioridad a la tecnología significa actualizar los semáforos y su funcionamiento y añadir señales para peatones y otros equipos de seguridad.',
      },
      other: {
        TITLE: 'Otros',
        DESCRIPTION: '¿Se nos ha escapado algo?',
        INPUT_PLACEHOLDER: 'Introduzca su propio valor aquí..',
      },
    },
    BUDGET_WARNING: 'Por favor, asegúrese de que sus prioridades suman el 100% antes de enviar sus valores.',
    BUTTON_TEXT: 'VER RESULTADO',
  },
  OUTCOME_TEXT: {
    MORE_PRIORITY_TITLE: 'RESULTADO: En base a sus valores, usted recomienda a la ciudad dar prioridad a...',
    LESS_PRIORITY_TITLE: 'y gastar menos en:',
    MORE_RECOMMENDED_TITLE:
      'Usted ha decidido no dar prioridad a ningún valor. Recomienda a la ciudad que priorice... ',
    CATEGORIES: {
      access:
        'Conexiones con lugares de destino: proyectos que facilitan el acceso a escuelas, parques, bibliotecas, centros comunitarios, tiendas locales, empleos y otros lugares.',
      climate:
        'Calidad del aire y salud: proyectos que fomenten los desplazamientos a pie, en bicicleta y en vehículos eléctricos.',
      equity:
        'Equidad: proyectos en comunidades que históricamente no han visto inversion, que carecen de infraestructuras básicas o que son comunidades de color con bajos ingresos',
      maintenance:
        'Mantenimiento: proyectos para arreglar baches, repavimentar calles y caminos y añadir rampas en los pasos de peatones',
      safety: 'Seguridad: proyectos donde se producen las colisiones más graves y mortales.',
      technology:
        'Tecnología: proyectos que mejoran los semáforos y su funcionamiento y añaden señales para peatones y otros equipos de seguridad',
      other: 'Algo más',
    },
    BOTTOM_PAGE_TEXT: (
      <div>
        RESULTADO: Basado en los controles deslizantes de cada valor. Haga clic en el botón VALORES para cambiar los
        resultados.
      </div>
    ),
    BUDGET_BUTTON: 'Valores',
    SUBMIT_BUTTON: 'Enviar',
  },
  SUCCESS_TEXT: {
    TITLE: 'Gracias.',
    SUBTITLE: 'Sus prioridades han sido enviadas con éxito. Agradecemos su participación.',
    SUBSCRIPTION_TEXT:
      'Por favor, siga participando. Haga clic en el botón siguiente para inscribirse en nuestra lista de correo electrónico.',
    SUBSCRIPTION_INPUT_TEXT: 'Introduzca su dirección de correo electrónico aquí:',
    SUBSCRIPTION_INPUT_ERROR: 'Dirección de correo electrónico inválida',
    SUBSCRIPTION_SUCCESS_TEXT: 'Correo electrónico suscrito',
    OPTIONAL_TITLE: 'Preguntas opcionales',
    OPTIONAL_SUBTITLE:
      'Por favor, responda a las siguientes preguntas para ayudarnos a garantizar que se trata de una muestra representativa de los residentes de la ciudad. Sus respuestas serán anónimas',
    SUBSCRIBE_BUTTON: 'Suscribir',
    SUBMIT_BUTTON: 'Enviar',
    SUBMISSION_SUCCESS_TEXT: '¡Gracias! Se han enviado sus respuestas.',
    FORM: {
      GENDER: {
        LABEL: 'Me identifico como:',
        VALUES: {
          FEMALE: 'Mujer (incluida la mujer trans)',
          MALE: 'Hombre (incluido el hombre trans)',
          GENDER_DIVERSE: 'Género diverso/no binario',
          OTHER: 'Otros',
          NON_DISCLOSURE: 'Prefiero no decirlo',
        },
        PLEASE_SPECIFY_LABEL: 'Especifique',
      },
      ETHNICITY: {
        LABEL: 'Identifico mi etnia como:',
        VALUES: {
          ASIAN: 'Asiático',
          BLACK: 'Negro/afroamericano',
          HISPANIC: 'Hispano/Latino',
          NATIVE_AMERICAN: 'Nativo Americano',
          PACIFIC_ISLANDER: 'Isleño del Pacífico',
          WHITE: 'Blanco',
          OTHER: 'Otros',
          NON_DISCLOSURE: 'Prefiero no decirlo',
        },
        PLEASE_SPECIFY_LABEL: 'Especifique',
      },
      AGE: {
        LABEL: '¿Cuál es su edad?',
        VALUES: {
          UNDER_18: 'Menos de 18 años',
          BETWEEN_18_AND_25: '18-24',
          BETWEEN_25_AND_35: '25-34',
          BETWEEN_35_AND_45: '35-44',
          BETWEEN_45_AND_55: '45-54',
          BETWEEN_55_AND_65: '55-64',
          OLDER_65: '65+',
          NON_DISCLOSURE: 'Prefiero no decirlo',
        },
      },
      LANGUAGE: {
        LABEL: '¿Qué idioma hablas en casa?',
        VALUES: {
          CHINESE: 'Chino',
          ENGLISH: 'Inglés',
          HMONG: 'Hmong',
          SPANISH: 'Español',
          VIETNAMESE: 'Vietnamita',
          OTHER: 'Otros',
          NON_DISCLOSURE: 'Prefiero no decirlo',
        },
        PLEASE_SPECIFY_LABEL: 'Especifique',
      },
      LGBTQ: {
        LABEL:
          '¿Se considera usted miembro de la comunidad de Lesbianas, Gays, Bisexuales, Transexuales y/o Queers (LGBTQ)?',
        VALUES: {
          NO: 'No',
          YES: 'Sí',
          NON_DISCLOSURE: 'Prefiero no decirlo',
        },
      },
      DISABILITY: {
        LABEL: '¿Hay alguien en su hogar que tenga una discapacidad que afecte a su movilidad, incluido usted mismo?',
        VALUES: {
          NO: 'No',
          YES: 'Sí',
          NON_DISCLOSURE: 'Prefiero no decirlo',
        },
      },
      INCOME: {
        LABEL: '¿Cuáles son los ingresos anuales de su hogar?',
        VALUES: {
          UNDER_15: 'Menos de 15.000 dólares',
          BETWEEN_15_AND_30: 'Entre 15.000 y 29.999 dólares',
          BETWEEN_30_AND_50: 'Entre 30.000 y 49.999 dólares',
          BETWEEN_50_AND_75: 'Entre 50.000 y 74.999 dólares',
          BETWEEN_75_AND_100: 'Entre 75.000 y 99.999 dólares',
          BETWEEN_100_AND_150: 'Entre 100.000 y 149.999 dólares',
          MORE_THAN_150: '150.000 dólares o más',
          NON_DISCLOSURE: 'Prefiero no decirlo',
        },
      },
      HOUSEHOLD: {
        LABEL: '¿Cuántas personas, adultos y niños, viven en su hogar, incluido usted?',
        VALUES: {
          ONE: '1',
          TWO: '2',
          THREE: '3',
          FOUR: '4',
          FIVE: '5',
          SIX_OR_MORE: '6 o más',
          NON_DISCLOSURE: 'Prefiero no decirlo',
        },
      },
      VEHICLES: {
        LABEL: '¿De cuántos automóviles disponen los miembros de su hogar, incluido su propio vehículo?',
        VALUES: {
          ZERO: '0',
          ONE: '1',
          TWO: '2',
          THREE: '3',
          FOUR_OR_MORE: '4 o más',
          NON_DISCLOSURE: 'Prefiero no decirlo',
        },
      },
      BICYCLES: {
        LABEL: '¿De cuántas bicicletas disponen los miembros de su hogar, incluida su propia bicicleta?',
        VALUES: {
          ZERO: '0',
          ONE: '1',
          TWO: '2',
          THREE: '3',
          FOUR_OR_MORE: '4 o más',
          NON_DISCLOSURE: 'Prefiero no decirlo',
        },
      },
      NEIGHBORHOOD: {
        LABEL: '¿En qué barrio de la ciudad vive?',
        NEIGHBORHOOD_NAMES: neighborhoods,
        VALUES: {
          OTHER: 'Otros',
          NON_DISCLOSURE: 'Prefiero no decirlo',
        },
      },
    },
  },
};

export default content;
