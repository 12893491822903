import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../index';

export type Input =
  | 'email'
  | 'gender'
  | 'ethnicity'
  | 'age'
  | 'language'
  | 'lgbtq'
  | 'disability'
  | 'income'
  | 'household'
  | 'vehicles'
  | 'bicycles'
  | 'neighborhood';

export interface FormState {
  email: string;
  gender: string;
  ethnicity: string;
  age: string;
  language: string;
  lgbtq: string;
  disability: string;
  income: string;
  household: string;
  vehicles: string;
  bicycles: string;
  neighborhood: string;
}

const initialState: FormState = {
  email: '',
  gender: '',
  ethnicity: '',
  age: '',
  language: '',
  lgbtq: '',
  disability: '',
  income: '',
  household: '',
  vehicles: '',
  bicycles: '',
  neighborhood: '',
};

const formSlice = createSlice({
  name: 'form',
  initialState,
  reducers: {
    setState: (state: FormState, action: PayloadAction<{ input: Input; value: string }>) => {
      const { input, value } = action.payload;
      state[input] = value;
    },
  },
});

//Export actions
export const { setState } = formSlice.actions;

//Export state
export const formState = (state: RootState) => state.formReducer;

export default formSlice.reducer;
