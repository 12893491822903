import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import loadable from '@loadable/component';
import FingerprintJS from '@fingerprintjs/fingerprintjs';

import { activePageState, setFingerprintId } from './store/slices/appSlice';
import { useWindowSize } from './hooks/useWindowSize';
import { tablet } from './configs/windowSizes';

import './App.scss';

import Loading from './components/loading/Loading';

//Bundle Welcome so its ready on load, lazy load others
import Languages from './components/languages/Languages';
const Welcome = loadable(() => import('./components/welcome/Welcome'));
const Zipcode = loadable(() => import('./components/zipcode/Zipcode'));
const Budget = loadable(() => import('./components/budget/Budget'));
const Outcome = loadable(() => import('./components/outcome/Outcome'));
const Success = loadable(() => import('./components/success/Success'));
const BudgetOutcome = loadable(() => import('./components/budgetOutcome/BudgetOutcome'));

function App() {
  const dispatch = useDispatch();
  const activePage = useSelector(activePageState);
  const SURVEY_CONCLUDED = true;

  const { width } = useWindowSize();

  useEffect(() => {
    (async () => {
      const fp = await FingerprintJS.load();

      const { visitorId } = await fp.get();

      dispatch(setFingerprintId(visitorId));
    })();
  }, [dispatch]);

  const isWindows = navigator.appVersion.indexOf('Win') !== -1;

  let activePageComponent;
  switch (activePage) {
    case 'welcome':
      activePageComponent = <Welcome />;
      break;
    case 'zipcode':
      activePageComponent = <Zipcode />;
      break;
    case 'budget':
      activePageComponent = width < tablet ? <Budget /> : <BudgetOutcome />;
      break;
    case 'outcome':
      activePageComponent = width < tablet ? <Outcome /> : <BudgetOutcome />;
      break;
    case 'success':
      activePageComponent = <Success />;
      break;
    default:
      activePageComponent = SURVEY_CONCLUDED ? <Welcome surveyConcluded={true} /> : <Languages />;
  }

  return (
    <div className={`App ${isWindows ? 'windows' : ''}`}>
      <Loading />
      <>{activePageComponent}</>
    </div>
  );
}

export default App;
