import neighborhoods from './neightborhoods';

const content = {
  TITLE: '沙加缅度运输优先计划',
  LANGUAGE_TEXT: {
    TITLE: 'Please Select Your Language',
    LANGUAGE_LIST: {
      english: 'English',
      spanish: 'Español',
      chinese: '汉语',
      hmong: 'Lus Hmoob',
      vietnamese: 'tiếng Việt',
    },
  },
  WELCOME_TEXT: {
    TEXT: (
      <>
        <p>对您来说，沙加缅度市应优先考虑哪些交通问题？例如：</p>

        <ul>
          <li>在低收入和有色人种社区进行运输投资？</li>
          <li>在没有人行道的地方修建人行道？</li>
          <li>修补路面坑洞？</li>
          <li>减少交通事故？</li>
        </ul>

        <p>运输优先计划将规划我市在运输方面应优先考虑何类工程项目，我们正在征求您的参与。</p>

        <p>沙加缅度市占地面积超过100平方英里，其中包括：</p>

        <ul>
          <li>1800英里街道</li>
          <li>250英里自行车道</li>
          <li>78英里的自行车/行人共享路径</li>
          <li>43,000柱路灯。</li>
        </ul>

        <p>
          修理和改善我市的所有道路，人行道，自行车道，桥梁和交通信号灯需要花费约50亿美元。但是，我市每年平均只有4,200万美元的运输经费。因此，要花上100多年才能达到我们目前计划的目标。我们应该优先做什么？
        </p>

        <p>
          有关更多信息，请访问{' '}
          <a target='_blank' rel='noreferrer' href='http://sactransportation.org/'>
            http://sactransportation.org/
          </a>
          .
        </p>
      </>
    ),
    START: '开始',
  },
  STEPPER_TEXT: {
    BUDGET: '预算',
    OUTCOME: '结果',
    SUCCESS: '提交',
  },
  ZIPCODE_TEXT: '请输入您的住宅邮政编码',
  BUDGET_TEXT: {
    DESCRIPTION_TEXT: (
      <>
        <p>
          请使用下面的几个滑块来分享您认为沙加缅度运输上的最重要的优先事项。由于市政预算有限，我们无法兼顾所有这些重要的事项，而必须对于优先考虑何些方面做出艰难的决择。
          您可以从以下描述的六项之中进行选择，也可以在页尾自己编写。
        </p>
        <p>
          <b>您在此处提交的信息将帮助我们衡量市内哪些运输工程项目将受到优先考虑和拨款。</b>{' '}
          比方说，如果您将维护工作列为优先事项，我市在信号灯技术方面的工程和项目的开支将相对减少。
          或比方您将空气质量和健康列为首要优先事项，这些项目将最先动工。
        </p>
        <p>
          <i>
            ，公交巴士和轻轨由地区捷运局（RT）-----而不是沙加缅度市政------运营。
            捷运局有独立的经费预算，所以以下列表没有提供公交服务作为一项。 要与捷运局分享您的想法，请访问：{' '}
            <a href='http://www.sacrt.com/customerfeedback/' rel='noreferrer' target='_blank'>
              http://www.sacrt.com/customerfeedback/
            </a>
          </i>
        </p>
      </>
    ),
    PROGRESS_TITLE: '优先项目总合',
    CATEGORIES: {
      access: {
        TITLE: '各地连通性',
        DESCRIPTION:
          '优先考虑市区各地连通性意味着以可使您更便利地到达学校、公园、图书馆、社区中心、本地商店、工作场所和其他地方的工程项目为首要目标，例如人行道、人行横道、自行车道、桥梁和街道的改善。',
        PLACES_TEXT: '哪些地方应该优先？选择您的前三名：',
        PLACES: {
          COMMUNITY_CENTERS: '社区中心',
          JOBS: '工作',
          LIBRARIES: '图书馆',
          PARKS: '公园',
          SCHOOLS: '学校',
          SHOPS: '商店',
          OTHER: '其他',
        },
      },
      climate: {
        TITLE: '空气质量和健康',
        DESCRIPTION:
          '优先考虑空气质量和健康意味着以鼓励步行，自行车和电动汽车的工程项目为首要目标，例如人行道、人行横道、自行车道、公共交通或电动汽车充电站。',
      },
      equity: {
        TITLE: '平权',
        DESCRIPTION:
          '优先考虑平权意味着以改善常年来市政方面投资不足的、缺乏基本基础设施的、低收入的、或有色人中聚集的社区的交通条件为首要目标，例如建设或整修人行道、人行横道或自行车道，维护受损的路面，或改善与公交站的连通。',
      },
      maintenance: {
        TITLE: '维护工作',
        DESCRIPTION:
          '优先考虑道路维护工作意味着以修复道路破损为首要目标，例如修复路面坑洼，重新铺设街道、人行道和自行车道，和在路口人行横道上增加路缘坡道。',
      },
      safety: {
        TITLE: '道路安全',
        DESCRIPTION:
          '优先考虑安全意味着以改进有出现导致死亡或重伤的交通事故的各地点为首要目标，例如改善交通标志、道路划线、人行横道、自行车道和交通信号灯，或进行道路减速或车道缩减工程。',
      },
      technology: {
        TITLE: '信号灯技术',
        DESCRIPTION:
          '优先考虑信号灯技术意味着以升级交通信号灯和信号灯操作为首要目标，也包括在更多的路口增加行人信号灯和其他安全设备。',
      },
      other: {
        TITLE: '其他',
        DESCRIPTION: '我们忽略了什么吗？',
        INPUT_PLACEHOLDER: '您可以在此添加自己的优先项目。',
      },
    },
    BUDGET_WARNING: '在提交您的回复之前，请确保您的优先项目总合达到100％。',
    BUTTON_TEXT: '查看结果',
  },
  OUTCOME_TEXT: {
    MORE_PRIORITY_TITLE: '结果：根据您的价值观，您建议沙加缅度市优先考虑…',
    LESS_PRIORITY_TITLE: '并在以下方面减少支出：',
    MORE_RECOMMENDED_TITLE: '您尚未选择优先考虑任何一个值。您建议沙加缅度市优先考虑……',
    CATEGORIES: {
      access: '各地连通性：使您更便利地到达学校、公园、图书馆、社区中心、本地商店、工作场所和其他地方的工程项目。',
      climate: '空气质量与健康：鼓励步行，骑自行车和电动汽车的工程项目。',
      equity:
        '平权：改善常年来市政方面投资不足的、缺乏基本基础设施的、低收入的、或有色人中聚集的社区的交通条件的工程项目',
      maintenance: '维护工作：修复路面坑洼，重新铺设街道、人行道和自行车道，和在路口人行横道上增加路缘坡道的工程项目',
      safety: '道路安全：改进有出现导致死亡或重伤的交通事故的各地点的工程项目。',
      technology: '信号灯技术：升级交通信号灯和信号灯操作并添加行人信号灯和其他安全设备的工程项目',
      other: '其他',
    },
    BOTTOM_PAGE_TEXT: <div>结果：基于每个项目的滑块。 单击下面的“项目”按钮以更改结果。</div>,
    BUDGET_BUTTON: '项目',
    SUBMIT_BUTTON: '提交',
  },
  SUCCESS_TEXT: {
    TITLE: '谢谢！',
    SUBTITLE: '您的回复已成功提交。 感谢您的参与。',
    SUBSCRIPTION_TEXT: '请保持联系！ 点击下面的按钮以加入我们的电子邮件列表。',
    SUBSCRIPTION_INPUT_TEXT: '在这里输入您的电子邮件地址：',
    SUBSCRIPTION_INPUT_ERROR: '电子邮件地址',
    SUBSCRIPTION_SUCCESS_TEXT: '訂閱的電子郵件',
    OPTIONAL_TITLE: '自愿回答题',
    OPTIONAL_SUBTITLE: '请回答以下问题，以帮助我们确保获得代表性的城市居民样本。您的答案将保持匿名。',
    SUBSCRIBE_BUTTON: '訂閱',
    SUBMIT_BUTTON: '提交',
    SUBMISSION_SUCCESS_TEXT: '謝謝！您的回复已提交。',
    FORM: {
      GENDER: {
        LABEL: '我的性别认同为：',
        VALUES: {
          FEMALE: '女性（包括跨性別女性）',
          MALE: '男性（包括跨性別男性）',
          GENDER_DIVERSE: '非二元性别',
          OTHER: '其他',
          NON_DISCLOSURE: '不想说',
        },
        PLEASE_SPECIFY_LABEL: '请明确说明',
      },
      ETHNICITY: {
        LABEL: '我的种族认同为：',
        VALUES: {
          ASIAN: '亚裔',
          BLACK: '黑人/非裔',
          HISPANIC: '西裔/拉丁裔',
          NATIVE_AMERICAN: '美洲原住民',
          PACIFIC_ISLANDER: '太平洋岛民',
          WHITE: '白人',
          OTHER: '其他',
          NON_DISCLOSURE: '不想说',
        },
        PLEASE_SPECIFY_LABEL: '请明确说明',
      },
      AGE: {
        LABEL: '您的年龄段为？',
        VALUES: {
          UNDER_18: '未满18岁',
          BETWEEN_18_AND_25: '18-24',
          BETWEEN_25_AND_35: '25-34岁',
          BETWEEN_35_AND_45: '35-44岁',
          BETWEEN_45_AND_55: '45-54岁',
          BETWEEN_55_AND_65: '55-64',
          OLDER_65: '65岁以上',
          NON_DISCLOSURE: '不想说',
        },
      },
      LANGUAGE: {
        LABEL: '您在家里讲什么语言？',
        VALUES: {
          CHINESE: '汉语',
          ENGLISH: '英语',
          HMONG: '苗语',
          SPANISH: '西班牙语',
          VIETNAMESE: '越南语',
          OTHER: '其他',
          NON_DISCLOSURE: '不想说',
        },
        PLEASE_SPECIFY_LABEL: '请明确说明',
      },
      LGBTQ: {
        LABEL: '您是否认为自己是LGBTQ（同性恋者、双性恋者和/或跨性别者）社区的成员？',
        VALUES: {
          NO: '否',
          YES: '是',
          NON_DISCLOSURE: '不想说',
        },
      },
      DISABILITY: {
        LABEL: '包括您自己，您家中是否有任何人有影响其行动能力的残疾？',
        VALUES: {
          NO: '否',
          YES: '是',
          NON_DISCLOSURE: '不想说',
        },
      },
      INCOME: {
        LABEL: '您的家庭年收入是多少？',
        VALUES: {
          UNDER_15: '15,000美元以下',
          BETWEEN_15_AND_30: '15,000美元至29,999美元',
          BETWEEN_30_AND_50: '30,000美元至49,999美元',
          BETWEEN_50_AND_75: '50,000美元至74,999美元',
          BETWEEN_75_AND_100: '75,000美元至99,999美元',
          BETWEEN_100_AND_150: '100,000美元至149,999美元',
          MORE_THAN_150: '150,000美元以上',
          NON_DISCLOSURE: '不想说',
        },
      },
      HOUSEHOLD: {
        LABEL: '包括您自己和所有成人和儿童，您的家中有多少人？',
        VALUES: {
          ONE: '1',
          TWO: '2',
          THREE: '3',
          FOUR: '4',
          FIVE: '5',
          SIX_OR_MORE: '6或以上',
          NON_DISCLOSURE: '不想说',
        },
      },
      VEHICLES: {
        LABEL: '包括您自己的车辆，您和您的家庭成员有多少辆汽车可供使用？',
        VALUES: {
          ZERO: '0',
          ONE: '1',
          TWO: '2',
          THREE: '3',
          FOUR_OR_MORE: '4辆或以上',
          NON_DISCLOSURE: '不想说',
        },
      },
      BICYCLES: {
        LABEL: '包括您自己的自行车，您和您的家庭成员有多少辆自行车可供使用？',
        VALUES: {
          ZERO: '0',
          ONE: '1',
          TWO: '2',
          THREE: '3',
          FOUR_OR_MORE: '4辆或以上',
          NON_DISCLOSURE: '不想说',
        },
      },
      NEIGHBORHOOD: {
        LABEL: '您居住在沙加缅度市的哪个街坊/街区/邻里？',
        NEIGHBORHOOD_NAMES: neighborhoods,
        VALUES: {
          OTHER: '其他',
          NON_DISCLOSURE: '不想说',
        },
      },
    },
  },
};

export default content;
