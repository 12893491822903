import { useSelector } from 'react-redux';
import { loadingState } from '../../store/slices/appSlice';

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

import './loading.scss';

const Loading = () => {
  const loading = useSelector(loadingState);

  return (
    <Backdrop className='backdrop' open={loading}>
      <CircularProgress />
    </Backdrop>
  );
};

export default Loading;
